import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const MessageThanks = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container">
      <h1>vielen Dank für ihre nachricht</h1>
      <h3>
        wir werden uns mit ihnen ggfs. in verbindung setzen.
      </h3>
    </div>
  </Layout>
)

export default MessageThanks
